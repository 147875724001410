import React, { Component } from "react";
// import logoBlack from "../images/tuzagInside.svg";
import logoWhite from "../images/tuzagTCSWhite.svg";
import { Link, animateScroll } from "react-scroll";
import { navigate } from "gatsby";
export default class Navbar extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	tailorData(key, mid, raw = false) {
		try {
			if (this.props.tailorData) {
				if (raw) {
					return this.props.tailorData[mid][key];
				}
				return (
					<span
						dangerouslySetInnerHTML={{
							__html: this.props.tailorData[mid][key]
						}}
					/>
				);
			} else {
				return <span />;
			}
		} catch (e) {
			//do nothing
		}
	}

	render() {
		return (
			<header className="header">
				<nav
					className={`navbar navbar-default navbar-fixed-top ${
						this.props.opaque ? "opaque" : ""
					}`}
				>
					<div className="container">
						<div className="navbar-header">
							<button
								data-target="#navbar-collapse-02"
								data-toggle="collapse"
								className="navbar-toggle"
								type="button"
							>
								<span className="sr-only">Toggle navigation</span>
								<span className="icon-bar" />
								<span className="icon-bar" />
								<span className="icon-bar" />
							</button>

							<div
								onClick={() => {
									if (this.props.privacyPolicy) {
										navigate("/");
									} else {
										animateScroll.scrollToTop();
									}
								}}
								className="navbar-brand"
								id="logo"
								style={{ cursor: "pointer", padding: 10 }}
							>
								<img
									src={logoWhite}
									alt="tuzag logo"
									style={{
										maxHeight: "100%",
										display:
											this.props.scrollTop > 400 || this.props.opaque
												? "block"
												: "none"
									}}
								/>
							</div>
						</div>
						{this.props.privacyPolicy ? null : (
							<div id="navbar-collapse-02" className="collapse navbar-collapse">
								<ul className="nav navbar-nav navbar-right">
									<li>
										<Link offset={-80} smooth={true} to="tuzagDesign">
											{this.tailorData("tuzagDesign", 4243)}
										</Link>
									</li>

									<li>
										<Link offset={-80} smooth={true} to="tuzagTailor">
											{this.tailorData("tuzagTailor", 4243)}
										</Link>
									</li>

									<li>
										<Link offset={-80} smooth={true} to="3DStorytelling">
											{this.tailorData("3DStorytelling", 4243)}
										</Link>
									</li>

									<li>
										<Link offset={-80} smooth={true} to="demo">
											{this.tailorData("demo", 4243)}
										</Link>
									</li>

									<li>
										<Link offset={-80} smooth={true} to="about">
											{this.tailorData("about", 4243)}
										</Link>
									</li>

									<li>
										<Link
											offset={-80}
											smooth={true}
											to="contact"
											className="discover-btn"
										>
											{this.tailorData("stayInformed", 4243)}
										</Link>
									</li>
								</ul>
							</div>
						)}
					</div>
				</nav>
			</header>
		);
	}
}
